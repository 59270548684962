<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :width="800"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="移动销卡"
      @ok="handleSubmit"
    >
      <a-row>
        <a-form
          class="simiot-compact-form"
          :form="form"
          @submit="handleSubmit"
        >
          <a-col :span="24">
            <a-form-item
              :wrapper-col="{ span: 24 }"
              label="选择方式"
            >
              <a-radio-group v-model="cancelRadioValue" @change="onChange">
                <a-radio value="input_iccid">
                  输入卡号
                </a-radio>
                <a-radio value="all_expect_cancel_card">
                  全部可销移动卡
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              :wrapper-col="{ span: 24 }"
            >
              <common-iccid-input
                :rows="15"
                v-decorator="['iccids', {
                  initialValue: { data: this.iccids },
                  rules: [{ validator: checkIccids }],
                }]"
                v-if="cancelRadioValue === 'input_iccid'"
              />
              <all-expect-cancel-card
                :carrier-type="carrierType"
                v-decorator="['iccids', {
                  initialValue: { data: this.iccids },
                  rules: [{ validator: checkCardsCount }],
                }]"
                :cards-count.sync="cardsCount"
                v-else-if="cancelRadioValue === 'all_expect_cancel_card'"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="备注">
              <a-textarea
                v-decorator="['remark', {
                  rules: [
                    { max: 200, message: '最多200个字符' },
                  ]
                }]"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            *此功能适用于移动卡
          </a-col>
        </a-form>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import CommonIccidInput from '@/components/IccidInput/Common'
import { cancelSimCard } from '@/api/batch_operation'
export default {
  name: 'AllChinaMobileCancelModal',
  components: {
    CommonIccidInput,
    AllExpectCancelCard: () => import('@/views/cancel_card_manages/AllExpectCancelCard.vue')
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    iccids: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'china_telecom_cancel_info' }),
      submitting: false,
      cancelRadioValue: 'input_iccid',
      carrierType: 'china_mobile',
      cardsCount: 0,
      isSubmitByIccidInput: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    cancelRadioValue() {
      this.isSubmitByIccidInput = this.cancelRadioValue === 'input_iccid'
    }
  },
  methods: {
    onChange(e) {
      this.cancelRadioValue = e.target.value
    },

    checkIccids(rule, value, callback) {
      if (!value || !value.cardsCount) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入卡号')
        return
      }

      if (value.validateStatus === 'error') {
        callback(value.errorMsg)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    checkCardsCount(rule, value, callback) {
      if (this.cardsCount === 0) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('待销卡数为0')
        return
      }

      if (value.validateStatus === 'error') {
        callback(value.errorMsg)
        return
      }

      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            iccids: values.iccids.data,
            carrier_type: this.carrierType,
            carrier_account_id: 0,
            is_submit_by_iccid_input: this.isSubmitByIccidInput
          }

          cancelSimCard(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
              this.$success({
                title: '提交成功',
                content: (
                  <div>
                    <p>数据更新已提交，具体数据更新详情请至 【系统查询】-【批量操作查询】 查看</p>
                  </div>
                )
              })
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
